import { invoke } from '@withease/factories'
import type { StoreValue } from 'effector'
import { combine, sample } from 'effector'
import { take } from 'remeda'
import type { AnalyticsServiceComponents } from '~/api/analytics'
import { formatAxisDate } from '~/shared/lib/chart/prepare-range/prepare-range'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import {
  chartPeriods,
  type ChartPeriodKeys,
} from '../shared/chart_period/model'
import { unsubsCohortsQuery } from './model'

export const unsubsCohortChartPeriodAtom = invoke(() =>
  createStateAtom<ChartPeriodKeys>({ defaultState: 'Всего' }),
)

export const $chartPeriodOptions = combine(
  [unsubsCohortsQuery.$data],
  ([queryData]) => {
    const daysCount = queryData?.stats.length ?? 0

    return chartPeriods.map(period => ({
      value: period.key,
      label: period.key,
      disabled: period.days > daysCount,
    }))
  },
)

/** Если запрос начался, то сбрасываем период */
sample({
  clock: unsubsCohortsQuery.started,
  target: unsubsCohortChartPeriodAtom.reset,
})

export type UnsubsCohortsChartData = StoreValue<typeof $chartData>

export const $chartData = combine(
  [unsubsCohortsQuery.$data, unsubsCohortChartPeriodAtom.$value],
  ([data, chartPeriodKey]) => {
    const daysCount =
      chartPeriods.find(period => period.key === chartPeriodKey)?.days ?? 0

    const items =
      daysCount > 0 ? take(data?.stats ?? [], daysCount) : (data?.stats ?? [])

    const dates = items
      .map(({ start_date, end_date }) => [start_date, end_date])
      .flat()

    const showYear =
      new Set(dates.map(date => new Date(date).getFullYear())).size > 1

    const showDay =
      new Set(dates.map(date => new Date(date).getDate())).size > 1

    const showTime =
      new Set(dates.map(date => new Date(date).toLocaleTimeString('ru-RU')))
        .size > 1

    const points = items.map(
      ({
        start_date,
        unsub,
        unsub_from_new,
        unsub_from_base,
        percent_unsub_base,
        percent_unsub_new,
      }) => {
        return {
          x: formatAxisDate({ date: start_date, showYear, showDay, showTime }),
          y: {
            unsub: {
              label: 'Всего',
              value: unsub,
              percentValue: null,
              color: '#525252',
            },
            unsub_from_new: {
              label: 'Новые',
              value: unsub_from_new,
              percentValue: percent_unsub_new,
              color: '#D60001',
            },
            unsub_from_base: {
              label: 'Остальные',
              value: unsub_from_base,
              percentValue: percent_unsub_base,
              color: '#525252',
            },
          },
        }
      },
    )

    const maxValue = Math.max(
      ...items
        .map(({ unsub, unsub_from_new, unsub_from_base }) => [
          Math.abs(unsub),
          Math.abs(unsub_from_new),
          Math.abs(unsub_from_base),
        ])
        .flat(),
    )

    const total = daysCount === 0 ? data?.total : items[daysCount - 1]

    return {
      points,
      maxValue,
      total: prepareTotal(total),
    }
  },
)

function prepareTotal(
  data?: AnalyticsServiceComponents<'UnsubNewBasePercents'>,
) {
  if (!data) return null

  return {
    items: [
      {
        key: 'total',
        value: data.first_n_days_unsub,
        percentValue: null,
        label: 'Всего',
        color: '#525252',
      },
      {
        key: 'from_new',
        value: data.first_n_days_unsub_new,
        percentValue: data.first_n_days_percent_unsub_new,
        label: 'Новые',
        color: '#D60001',
      },
      {
        key: 'from_base',
        value: data.first_n_days_unsub_base,
        percentValue: data.first_n_days_percent_unsub_base,
        label: 'Остальные',
        color: '#525252',
      },
    ],
  }
}
