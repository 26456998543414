import { $channelId } from '@channel/channel.model'
import {
  $$datetimeRangeFilterModel,
  unsubsEndDatetime,
} from '@channel/widgets/analytics_filters/datetime_range_filter/model'
import { sharedInviteLinksFilterModel } from '@channel/widgets/analytics_filters/invite_links_filter/model'
import type { RemoteOperationParams } from '@farfetched/core'
import { concurrency, createQuery } from '@farfetched/core'
import { sample } from 'effector'
import { createAnalyticsServiceEffect } from '~/api/analytics'
import { formatDateWithOffset } from '~/shared/lib/chart/prepare-range/prepare-range'
import { prepareEndDatetime } from '~/shared/lib/date/prepare-end-datetime'
import { TrafficsTabGate } from '../../model'

export const unsubsCohortsQuery = createQuery({
  effect: createAnalyticsServiceEffect({
    method: 'post',
    url: '/v2/traffic/get_unsub_precents',
  }),
})

concurrency(unsubsCohortsQuery, {
  strategy: 'TAKE_LATEST',
})

sample({
  clock: [
    TrafficsTabGate.open,
    sharedInviteLinksFilterModel.$inviteLinksHashList,
    $$datetimeRangeFilterModel.$dateTimeRange,
    unsubsEndDatetime.$value,
  ],
  source: {
    isActiveTab: TrafficsTabGate.status,
    channelId: $channelId,
    datetimeRange: $$datetimeRangeFilterModel.$dateTimeRange,
    end_datetime_unsub: unsubsEndDatetime.$value,
    invite_links_hash: sharedInviteLinksFilterModel.$inviteLinksHashList,
  },
  filter({
    isActiveTab,
    channelId,
    datetimeRange: { start_datetime, end_datetime },
  }) {
    return isActiveTab && !!(channelId && start_datetime && end_datetime)
  },
  fn: ({
    channelId,
    datetimeRange: { start_datetime, end_datetime },
    end_datetime_unsub,
    invite_links_hash,
  }) => {
    return {
      body: {
        channel_id: channelId!,
        sub_start_datetime: formatDateWithOffset(new Date(start_datetime!)),
        sub_end_datetime: formatDateWithOffset(new Date(end_datetime!)),
        start_datetime: formatDateWithOffset(new Date(start_datetime!)),
        end_datetime: formatDateWithOffset(
          prepareEndDatetime(end_datetime_unsub ?? new Date()),
        ),
        invite_links_hash,
      },
    } satisfies RemoteOperationParams<typeof unsubsCohortsQuery>
  },
  target: unsubsCohortsQuery.refresh,
})

sample({
  clock: TrafficsTabGate.close,
  target: [unsubsCohortsQuery.reset] as const,
})
