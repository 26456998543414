import { invoke } from '@withease/factories'
import { HARDCODED_tagTypesNames } from '~/HARDCODED'
import { createStateAtom } from '~/shared/lib/factories/create-state'

const dataViewTypeKeys = ['Link', ...HARDCODED_tagTypesNames] as const

type DataViewTypeKey = (typeof dataViewTypeKeys)[number]

const dataViewTypeLabels = {
  Link: 'Название',
  Source: 'Source',
  Medium: 'Medium',
  Campaign: 'Campaign',
  Content: 'Content',
} as const satisfies Record<DataViewTypeKey, string>

export const dataViewTypeOptions = dataViewTypeKeys.map(key => {
  return {
    value: key,
    label: dataViewTypeLabels[key],
  }
})

export const adsCampaignsChartDataViewTypeAtom = invoke(() =>
  createStateAtom<DataViewTypeKey>({ defaultState: 'Link' }),
)
