import { invoke } from '@withease/factories'
import { combine, sample } from 'effector'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import type { ChartPeriodKeys } from '../shared/chart_period/model'
import { chartPeriods } from '../shared/chart_period/model'
import { trafficPercentsQuery } from './model'

export const unsubsPercentsChartPeriodAtom = invoke(() =>
  createStateAtom<ChartPeriodKeys>({ defaultState: 'Всего' }),
)

export const $chartPeriodOptions = combine(
  [trafficPercentsQuery.$data],
  ([queryData]) => {
    const daysCount = queryData?.stats.length ?? 0

    return chartPeriods.map(period => ({
      value: period.key,
      label: period.key,
      disabled: period.days > daysCount,
    }))
  },
)

sample({
  clock: trafficPercentsQuery.started,
  target: unsubsPercentsChartPeriodAtom.reset,
})
