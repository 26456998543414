import { $channelId } from '@channel/channel.model'
import { $$datetimeRangeFilterModel } from '@channel/widgets/analytics_filters/datetime_range_filter/model'
import { sharedInviteLinksFilterModel } from '@channel/widgets/analytics_filters/invite_links_filter/model'
import type { RemoteOperationParams } from '@farfetched/core'
import { concurrency, createQuery } from '@farfetched/core'
import { sample } from 'effector'
import { createAnalyticsServiceEffect } from '~/api/analytics'
import { formatDateWithOffset } from '~/shared/lib/chart/prepare-range/prepare-range'
import { TrafficsTabGate } from '../../model'

export const presenceUsernameQuery = createQuery({
  effect: createAnalyticsServiceEffect({
    method: 'post',
    url: '/v2/traffic/subs_unsubs_username',
  }),
})

concurrency(presenceUsernameQuery, {
  strategy: 'TAKE_LATEST',
})

sample({
  clock: TrafficsTabGate.close,
  target: [presenceUsernameQuery.reset] as const,
})

sample({
  clock: [
    TrafficsTabGate.open,
    $$datetimeRangeFilterModel.$dateTimeRange,
    sharedInviteLinksFilterModel.$inviteLinksHashList,
  ],
  source: {
    isActiveTab: TrafficsTabGate.status,
    channelId: $channelId,
    datetimeRange: $$datetimeRangeFilterModel.$dateTimeRange,
    invite_links_hash: sharedInviteLinksFilterModel.$inviteLinksHashList,
  },
  filter({
    channelId,
    datetimeRange: { start_datetime, end_datetime },
    isActiveTab,
  }) {
    const value = isActiveTab && !!(channelId && start_datetime && end_datetime)
    return value
  },
  fn: ({
    channelId,
    datetimeRange: { start_datetime, end_datetime },
    invite_links_hash,
  }) => {
    return {
      body: {
        channel_id: channelId!,
        start_datetime: formatDateWithOffset(new Date(start_datetime!)),
        end_datetime: formatDateWithOffset(new Date(end_datetime!)),
        invite_links_hash,
      },
    } satisfies RemoteOperationParams<typeof presenceUsernameQuery>
  },
  target: presenceUsernameQuery.refresh,
})
