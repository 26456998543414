import type { IterableElement } from 'type-fest'

export const chartPeriods = [
  { key: 'за 1 день', days: 1 },
  { key: 'за 2 дня', days: 2 },
  { key: 'за 3 дня', days: 3 },
  { key: 'за 7 дней', days: 7 },
  { key: 'за 14 дней', days: 14 },
  { key: 'за 30 дней', days: 30 },
  { key: 'Всего', days: 0 },
] as const

export type ChartPeriod = IterableElement<typeof chartPeriods>
export type ChartPeriodKeys = Pick<ChartPeriod, 'key'>['key']
