import type { RemoteOperationResult } from '@farfetched/core'
import { invoke } from '@withease/factories'
import type { StoreValue } from 'effector'
import { combine } from 'effector'
import { entries, map, omit, pipe } from 'remeda'
import { formatAxisDate } from '~/shared/lib/chart/prepare-range/prepare-range'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import { colors } from '~/shared/ui/colors'
import { presenceUsernameQuery } from './query_model'

type QueryData = RemoteOperationResult<typeof presenceUsernameQuery>
type QueryDataStatsItem = QueryData['stats'][number]

const usernamesChartDataKeys = [
  'subs',
  'unsubs',
  'username',
  'no_username',
] as const satisfies (
  | keyof Pick<QueryDataStatsItem, 'subs' | 'unsubs'>
  | Exclude<
      keyof QueryDataStatsItem[keyof Pick<
        QueryDataStatsItem,
        'subs' | 'unsubs'
      >],
      'total'
    >
)[]

export type UsernameChartDataKeys = (typeof usernamesChartDataKeys)[number]

type ExtractDataKeys<K extends UsernameChartDataKeys> =
  K extends Extract<UsernameChartDataKeys, 'subs' | 'unsubs'>
    ? Extract<UsernameChartDataKeys, 'username' | 'no_username'>
    : Extract<UsernameChartDataKeys, 'subs' | 'unsubs'>

type PeriodStatsKeys = keyof Pick<QueryData, 'total' | 'current_day'>
export type PointItem = StoreValue<typeof $chartData>['points'][number]

export const getPointDataKey = <K extends UsernameChartDataKeys>(
  pointY: PointItem['y'],
  activeKey: K,
  key: ExtractDataKeys<K>,
) => {
  if (activeKey === 'subs' || activeKey === 'unsubs') {
    return pointY[activeKey as 'subs' | 'unsubs'][
      key as ExtractDataKeys<'subs' | 'unsubs'>
    ]
  } else {
    return pointY[activeKey as 'username' | 'no_username'][
      key as ExtractDataKeys<'username' | 'no_username'>
    ]
  }
}

export const getCommonData = <K extends UsernameChartDataKeys>(
  pointY: PointItem['y'],
  activeKey: K,
) => {
  if (activeKey === 'subs' || activeKey === 'unsubs') {
    return {
      positive: pointY[activeKey as 'subs' | 'unsubs']['username'],
      negative: pointY[activeKey as 'subs' | 'unsubs']['no_username'],
    }
  } else {
    return {
      positive: pointY[activeKey as 'username' | 'no_username']['subs'],
      negative: pointY[activeKey as 'username' | 'no_username']['unsubs'],
    }
  }
}

export const splitKeys = <
  T extends UsernameChartDataKeys,
  R = T extends 'subs' | 'unsubs'
    ? { positive: 'username'; negative: 'no_username' }
    : { positive: 'subs'; negative: 'unsubs' },
>(
  activeKey: T,
): R => {
  switch (activeKey) {
    case 'subs':
    case 'unsubs':
      return { positive: 'username', negative: 'no_username' } as R
    default: {
      return { positive: 'subs', negative: 'unsubs' } as R
    }
  }
}

export const labelsMap = {
  username: 'с никнеймом',
  no_username: 'без никнейма',
  subs: 'подписки',
  unsubs: 'отписки',
} satisfies Record<UsernameChartDataKeys, string>

export const colorsMap = {
  username: '#a855f7',
  no_username: colors.secondary,
  subs: '#a855f7',
  unsubs: colors.secondary,
} satisfies Record<UsernameChartDataKeys, string>

const periodStatsLabel = {
  current_day: 'За сегодня',
  total: 'За период',
} satisfies Record<PeriodStatsKeys, string>

export const dataKeysOptions = usernamesChartDataKeys.map(key => ({
  value: key,
  label: labelsMap[key],
}))

export const chartLines = usernamesChartDataKeys.map(key => ({
  key,
  label: labelsMap[key],
  color: colorsMap[key],
}))

export const usernamesChartActiveKeyAtom = invoke(() =>
  createStateAtom<UsernameChartDataKeys>({ defaultState: 'subs' }),
)

export const $chartData = combine([presenceUsernameQuery.$data], ([data]) => {
  const dates = (data?.stats ?? [])
    .map(({ start_date, end_date }) => [start_date, end_date])
    .flat()

  const showYear =
    new Set(dates.map(date => new Date(date).getFullYear())).size > 1

  const showDay = new Set(dates.map(date => new Date(date).getDate())).size > 1

  const showTime =
    new Set(dates.map(date => new Date(date).toLocaleTimeString('ru-RU')))
      .size > 1

  const points = (data?.stats ?? []).map(({ start_date, subs, unsubs }) => {
    return {
      x: formatAxisDate({ date: start_date, showYear, showDay, showTime }),
      y: {
        subs: {
          username: subs.username,
          no_username: subs.no_username,
        },
        unsubs: {
          username: unsubs.username,
          no_username: unsubs.no_username,
        },
        username: {
          subs: subs.username,
          unsubs: unsubs.username,
        },
        no_username: {
          subs: subs.no_username,
          unsubs: unsubs.no_username,
        },
      },
    }
  })

  const allValues = (data?.stats ?? [])
    .map(({ subs, unsubs }) => [
      ...Object.values(subs).map(Math.abs),
      ...Object.values(unsubs).map(Math.abs),
    ])
    .flat()

  const maxValue = Math.max(...allValues)

  const periodStats = data
    ? pipe(
        data,
        omit(['stats']),
        entries(),
        map(([k, v]) => ({
          label: periodStatsLabel[k],
          values: {
            subs: {
              username: v.subs.username,
              no_username: v.subs.no_username,
            },
            unsubs: {
              username: v.unsubs.username,
              no_username: v.unsubs.no_username,
            },
            username: {
              subs: v.subs.username,
              unsubs: v.unsubs.username,
            },
            no_username: {
              subs: v.subs.no_username,
              unsubs: v.unsubs.no_username,
            },
          },
        })),
      )
    : []

  return {
    points,
    maxValue,
    periodStats,
  }
})
