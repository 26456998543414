import { $channelId } from '@channel/channel.model'
import {
  concurrency,
  createMutation,
  createQuery,
  keepFresh,
} from '@farfetched/core'
import { invoke } from '@withease/factories'
import type { EventPayload } from 'effector'
import { combine, createEffect, createEvent, sample } from 'effector'
import { interval } from 'patronum'
import { sort } from 'remeda'
import { createAnalyticsServiceEffect } from '~/api/analytics'
import { getChannelByIdBaseFx } from '~/api/manage.effects'
import type { ApiType } from '~/api/types'
import { EMPTY_INVITE_LINK_HASH } from '~/constants'
import { pageVisibility } from '~/features/web_apis'
import { formatDateWithOffset } from '~/shared/lib/chart/prepare-range/prepare-range'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import { createDateTimeRangeModel } from '~/shared/lib/factories/date-range/create-date-range-model'
import type { GenerationType } from './generation_type'
import { unloadingReportsFiltersModel } from './uploading_links/model'

export const unloadingReportsModal = invoke(() => createDisclosureAtom())
export const unloadingReportsDatetimeRange = invoke(() =>
  createDateTimeRangeModel(),
)
export const unloadingReportsSubmitClicked = createEvent()

export const createUnloadingReportMutation = createMutation({
  effect: createAnalyticsServiceEffect({
    method: 'post',
    url: '/v2/reports',
  }),
})

const channelQuery = createQuery({
  effect: getChannelByIdBaseFx,
})

concurrency(channelQuery, {
  strategy: 'TAKE_LATEST',
})

export const unloadingReportsQuery = createQuery({
  effect: createAnalyticsServiceEffect({
    method: 'get',
    url: '/v2/reports',
  }),
  initialData: [],
})

concurrency(unloadingReportsQuery, {
  strategy: 'TAKE_LATEST',
})

export const $reportItems = combine([unloadingReportsQuery.$data], ([data]) => {
  return sort(data, (a, b) => {
    return new Date(a.created_at).getTime() < new Date(b.created_at).getTime()
      ? 1
      : -1
  })
})

const removeUnloadingReportMutation = createMutation({
  effect: createAnalyticsServiceEffect({
    method: 'delete',
    url: '/v2/reports/{report_id}',
  }),
})

export const fileName = invoke(createStateAtom<string>, {
  defaultState: '',
})

export const generationTypeAtom = invoke(() =>
  createStateAtom<GenerationType>({
    defaultState: 'by_events',
  }),
)

sample({
  clock: unloadingReportsModal.opened,
  source: $channelId,
  filter: Boolean,
  fn: channel_id =>
    ({
      params: {
        path: {
          channel_id: channel_id,
        },
      },
    }) satisfies EventPayload<typeof channelQuery.refresh>,
  target: channelQuery.refresh,
})

sample({
  clock: unloadingReportsModal.opened,
  source: $channelId,
  filter: Boolean,
  fn: channel_id =>
    ({
      params: {
        query: {
          channel_id: channel_id,
        },
      },
    }) satisfies EventPayload<typeof unloadingReportsQuery.start>,
  target: unloadingReportsQuery.start,
})

export const $isValidForm = combine(
  [$channelId, unloadingReportsDatetimeRange.$dateTimeRange],
  ([channelId, datetimeRange]) =>
    !!(channelId && datetimeRange.start_datetime && datetimeRange.end_datetime),
)

sample({
  clock: unloadingReportsSubmitClicked,
  source: {
    channelId: $channelId,
    datetimeRange: unloadingReportsDatetimeRange.$dateTimeRange,
    inviteLinksIds: unloadingReportsFiltersModel.$inviteLinksHashList,
    fileName: fileName.$value,
    generationType: generationTypeAtom.$value,
  },
  filter: $isValidForm,
  fn: ({
    channelId,
    datetimeRange,
    inviteLinksIds,
    fileName,
    generationType,
  }) => {
    return {
      params: {
        query: {
          generating_type: generationType,
        },
      },
      body: {
        channel_id: channelId!,
        invite_links_hash:
          inviteLinksIds.length === 0
            ? undefined
            : inviteLinksIds.map(hash => {
                if (hash === EMPTY_INVITE_LINK_HASH) {
                  return null
                } else {
                  return hash
                }
              }),
        file_name: fileName ? fileName.trim() : undefined,
        start_datetime: formatDateWithOffset(
          new Date(datetimeRange.start_datetime!),
        ),
        end_datetime: formatDateWithOffset(
          new Date(datetimeRange.end_datetime!),
        ),
      },
    } satisfies EventPayload<typeof createUnloadingReportMutation.start>
  },
  target: createUnloadingReportMutation.start,
})

export const downloadReportClicked =
  createEvent<Pick<ApiType<'logic__dto__report__Report'>, 'url'>>()

sample({
  clock: downloadReportClicked,
  fn: ({ url }) => url,
  target: createEffect<string, void>(url => {
    const anchor = document.createElement('a')
    anchor.href = `/storage/${url}`
    anchor.click()
    document.removeChild(anchor)
  }),
})

sample({
  clock: unloadingReportsModal.closed,
  target: [
    unloadingReportsDatetimeRange.reset,
    channelQuery.reset,
    unloadingReportsQuery.reset,
    createUnloadingReportMutation.reset,
    removeUnloadingReportMutation.reset,
    fileName.reset,
    unloadingReportsFiltersModel.resetAll,
  ] as const,
})

sample({
  clock: createUnloadingReportMutation.finished.success,
  target: [
    fileName.reset,
    unloadingReportsDatetimeRange.reset,
    unloadingReportsFiltersModel.resetAll,
  ] as const,
})

export const removeReportClicked =
  createEvent<
    Pick<ApiType<'logic__dto__report__Report'>, 'id' | 'channel_id'>
  >()

sample({
  clock: removeReportClicked,
  fn: ({ id, channel_id }) =>
    ({
      params: {
        path: {
          report_id: id,
        },
        query: {
          channel_id: channel_id,
        },
      },
    }) satisfies EventPayload<typeof removeUnloadingReportMutation.start>,
  target: removeUnloadingReportMutation.start,
})

const timeout = invoke(createStateAtom<number>, { defaultState: 1000 })

sample({
  clock: unloadingReportsQuery.refresh,
  source: timeout.$value,
  fn: timeout => timeout + 1000,
  target: timeout.set,
})

sample({
  clock: timeout.$value,
  filter: timeout => timeout > 15_000,
  target: timeout.reset,
})

const stopOnStatus = sample({
  clock: unloadingReportsQuery.$data,
  filter: data => data.every(({ status }) => status !== 'in_progress'),
})

const stopInterval = sample({
  clock: [unloadingReportsModal.closed, pageVisibility.hidden, stopOnStatus],
})

const startOnStatus = sample({
  clock: unloadingReportsQuery.$data,
  filter: data => data.some(({ status }) => status === 'in_progress'),
})

const startOnPageVisible = sample({
  clock: pageVisibility.visible,
  source: unloadingReportsQuery.$data,
  filter: data => data.some(({ status }) => status === 'in_progress'),
})

const startInterval = sample({
  clock: [startOnStatus, startOnPageVisible],
})

const { tick: checkReports } = interval({
  timeout: timeout.$value,
  start: startInterval,
  stop: stopInterval,
  leading: true,
})

keepFresh(unloadingReportsQuery, {
  triggers: [
    createUnloadingReportMutation.finished.success,
    removeUnloadingReportMutation.finished.success,
    checkReports,
  ],
})
