import { attach, createEffect } from 'effector'
import type { ReactNode } from 'react'
import { toast, type ExternalToast, type ToastT } from 'sonner'

type ToastType = Extract<
  ToastT['type'],
  'success' | 'info' | 'warning' | 'error'
>

type Props = {
  message: string | ReactNode
  type: ToastType
} & ExternalToast

export const showToastBaseFx = createEffect<Props, string | number>(
  ({ message, type, ...opts }) => {
    return toast[type](message, opts)
  },
)

type ToastId = string | number

export const closeToastBaseFx = createEffect<ToastId, ToastId>(toastId => {
  return toast.dismiss(toastId)
})

export const showSuccessToastFx = attach({
  effect: showToastBaseFx,
  mapParams(message: string) {
    return { message, type: 'success', duration: 5000 } satisfies Props
  },
})

export const showErrorToastFx = attach({
  effect: showToastBaseFx,
  mapParams(message: string) {
    return { message, type: 'error', duration: 5000 } satisfies Props
  },
})

export const showWarningToastFx = attach({
  effect: showToastBaseFx,
  mapParams(message: string) {
    return { message, type: 'warning', duration: 5000 } satisfies Props
  },
})
