import { $isChannelLoaded } from '@channel/channel.model'
import { invoke } from '@withease/factories'
import { sample } from 'effector'
import { createStateAtom } from '~/shared/lib/factories/create-state'
import { createDateTimeRangeModel } from '~/shared/lib/factories/date-range/create-date-range-model'

export const $$datetimeRangeFilterModel = invoke(() =>
  createDateTimeRangeModel(),
)

export const unsubsEndDatetime = invoke(createStateAtom<Date | undefined>, {
  defaultState: new Date(),
  skipVoid: false,
})

sample({
  clock: $$datetimeRangeFilterModel.$dateTimeRange,
  source: { unsubsEndDatetime: unsubsEndDatetime.$value },
  filter: (_, { end_datetime }) => !!end_datetime,
  fn: (_, { end_datetime }) => new Date(end_datetime!),
  target: unsubsEndDatetime.$value,
})

sample({
  clock: $isChannelLoaded,
  filter: is => !is,
  target: [$$datetimeRangeFilterModel.reset, unsubsEndDatetime.reset] as const,
})
