import { $channelId, $isChannelLoaded } from '@channel/channel.model'
import { invoke } from '@withease/factories'
import type { EffectParams } from 'effector'
import { attach, restore, sample } from 'effector'
import { closeToastBaseFx, showToastBaseFx } from '~/features/show_toast'
import { createInviteLinksWidgetModel } from './factory'

export const sharedInviteLinksFilterModel = invoke(() =>
  createInviteLinksWidgetModel({
    channelId: $channelId,
    isEnabled: $isChannelLoaded,
  }),
)

const toastFx = attach({ effect: showToastBaseFx })

sample({
  clock: sharedInviteLinksFilterModel.$noLinksFound,
  filter: Boolean,
  fn: () =>
    ({
      duration: Infinity,
      message: 'По заданным параметрам ссылки не найдены',
      type: 'warning',
      action: {
        label: 'Сбросить',
        onClick: () => {
          sharedInviteLinksFilterModel.resetAll()
        },
      },
      closeButton: true,
    }) satisfies EffectParams<typeof showToastBaseFx>,
  target: toastFx,
})

const $toastId = restore(toastFx.doneData, null)

sample({
  clock: [
    sharedInviteLinksFilterModel.$noLinksFound,
    sharedInviteLinksFilterModel.$inviteLinksHashList,
  ],
  source: {
    toastId: $toastId,
    inviteLinksIds: sharedInviteLinksFilterModel.$inviteLinksHashList,
    isNotFound: sharedInviteLinksFilterModel.$noLinksFound,
  },
  filter: ({ toastId, inviteLinksIds, isNotFound }) =>
    !!toastId && (!isNotFound || inviteLinksIds.length > 0),
  target: attach({
    source: $toastId,
    mapParams: (_, toastId) => toastId!,
    effect: closeToastBaseFx,
  }),
})
