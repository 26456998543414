import { invoke } from '@withease/factories'
import { createEffect, createEvent, sample } from 'effector'
import { createDisclosureAtom } from '~/shared/lib/factories/create-disclosure'
import { $userRegisteredAt } from '~/user/model'
import { TrafficsTabGate } from '../../gate'

const KEY = 'release_modal_traffic_v2'
const RELEASE_DATE = new Date(2024, 8, 23)

export const trafficReleaseDisclosureAtom = invoke(() => createDisclosureAtom())
export const trafficReleaseNextButtonClicked = createEvent()

const checkLsFx = createEffect(() => {
  return !!window.localStorage.getItem(KEY)
})

const setLsFx = createEffect(() => {
  window.localStorage.setItem(KEY, '1')
})

sample({
  clock: [TrafficsTabGate.open, $userRegisteredAt],
  source: { userRegisteredAt: $userRegisteredAt },
  filter: ({ userRegisteredAt }) =>
    !!userRegisteredAt &&
    new Date(userRegisteredAt).getTime() < RELEASE_DATE.getTime(),
  target: checkLsFx,
})

sample({
  clock: checkLsFx.doneData,
  filter: isDone => !isDone,
  target: trafficReleaseDisclosureAtom.open,
})

sample({ clock: trafficReleaseDisclosureAtom.closed, target: setLsFx })
